.left {
    animation-duration: 2s;
    animation-name: slideinLeft;
}

@keyframes slideinLeft{
    from{
        margin-left: 100%;
        width: 100%;
    }
    
    to{
        width: 100%;
        margin-left: 0%;
    }
}

.right {
    animation-duration: 2s;
    animation-name: slideinRight;
}

@keyframes slideinRight{
    from{
        margin-left: -100%;
    }
    
    to{
        margin-right: 0%;
    }
}
